<template>
  <div :style="{'background-image' : 'url(' + vendor.imgSelect  + ')'}" id="confirmationView">
    <div :style="{ backgroundColor: design.bgColorCheckout }" class="Check-height">
      <div class="relative" style="overflow: hidden">
        <confirmationReservationModal
          :reservationData="infoReservation"
          class="ConfirmBox"
          v-if="design && infoReservation"
        ></confirmationReservationModal>
      </div>
    </div>
  </div>
</template>
<script>
// Libraries Components
import { mapGetters } from 'vuex';
// Types - Modules
import globalTypes from '@/store/types/global';
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import confirmationReservationModal from '@/components/Checkout/confirmationReservationModal';
export default {
  data () {
    return {
      infoReservation: null
    };
  },
  components: {
    confirmationReservationModal
  },
  computed: {
    ...mapGetters({
      paymentType: whiteLabelTypes.getters.paymentType,
      reservation: reservationTypes.getters.reservationsUser,
      reservationLink: reservationTypes.getters.reservationsUserLink,
      processing: globalTypes.getters.processing,
      stateRequiredExperience: whiteLabelTypes.getters.getStateRequiredException,
      tip: reservationTypes.getters.getTip,
      integrations: [whiteLabelTypes.getters.integrations],
      integrationDefaults: [whiteLabelTypes.getters.integrationDefaults]
    })
  },
  created () {
    this.loadScript(this.integrations);
  },
  mounted () {
    this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
    this.loadScriptPersonalize(this.vendor.id);
    const dataParam = this.$route.query;
    if (Object.keys(dataParam).length > 0 && dataParam.id) {
      this.$http.post('/dashboard/reservationId', {
        reservationId: dataParam.id
      }).then(({ data }) => {
        if (data.length > 0) {
          const reservationDataQuery = data[0];
          reservationDataQuery.isFree = false;
          reservationDataQuery.dateTime = data[0].date;
          window.localStorage.setItem('_reservationOk', true);
          window.localStorage.setItem(
            '_reservation_link',
            JSON.stringify(reservationDataQuery)
          );
          this.$store.commit(reservationTypes.mutations.setReservationsUserLink);
          this.showModalInfo(this.reservationLink);
        }
      });
      setTimeout(() => {
        if (this.reservationLink === null || this.reservationLink === undefined) {
          if (this.embedSystem) {
            window.location.href = '/?embed=true';
          } else {
            window.location.href = '/';
          }
        }
      }, 1000);
    } else {
      this.$store.commit(reservationTypes.mutations.setReservationsUser);
      setTimeout(() => {
        if (this.reservation === null || this.reservation === undefined) {
          if (this.embedSystem) {
            window.location.href = '/?embed=true';
          } else {
            window.location.href = '/';
          }
        }
      }, 1000);
      this.showModalInfo(this.reservation);
    }

    this.$store.commit(
      whiteLabelTypes.mutations.setShowModalReservationConfirm
    );
  },
  methods: {
    showModalInfo (reservation) {
      console.log(reservation);
      if (window.localStorage.getItem('_reservationOk')) {
        window.localStorage.removeItem('_reservation');
        window.localStorage.removeItem('_reservation_time');
        window.localStorage.removeItem('payReservations');
        window.localStorage.removeItem('payReservationBoys');
        window.localStorage.removeItem('payReservationsFixed');
        window.localStorage.removeItem('applyStrong');
        window.localStorage.removeItem('pricing');
        window.localStorage.removeItem('_decoration_cost');
        window.localStorage.removeItem('zoneId');
        window.localStorage.removeItem('_oldPayExperience');
        window.localStorage.removeItem('_oldPayExperienceFull');
        window.localStorage.removeItem('_invalid_alert_experience');
        this.infoReservation = reservation;
      } else {
        if (this.embedSystem) {
          window.location.href = '/?embed=true';
        } else {
          window.location.href = '/';
        }
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/Confirmation/confirmationView.scss";
</style>
